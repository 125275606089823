import {i18n} from '../assets/i18n/index';
const $t = i18n.t;
// console.log('config::', $t);
// let ossConfig=process.env.OSS_CONFIG;

const MENU_DATA = [
    // {title:'新建项目',route:'Index',path:'/new',icon:'ib-plus'},
    {title:'翻译引擎',route:'EngineConfig',path:'/engine/config',icon:'ib-robot'},
    {title:'翻译项目',route:'ProjectList',path:'/project',icon:'ib-translate-o'},
    {title:'翻译记忆',route:'TermIndex',path:'/asset',icon:'ib-translate-asset'}, //语言资产
    // {title:'我的引擎',route:'EngineIndex',path:'/engine/index',icon:'ib-robot'},
    {title:'AI工具箱',route:'ToolsAlignIndex',path:'/tools',icon:'ib-toolbox', position:'end'},
    // {title:'帮助中心',route:'Index',path:'https://m15er90ta0.feishu.cn/docs/doccnpnEA2swEXwJ8yyeGs2sZFb',type:'jump', icon:'ib-help-o'},
];
const FILE_ACCEPT_TYPE=[
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'text/csv',
    'text/rtf',
    '.srt',
    '.sdlxliff',
    '.xliff',
    'application/pdf',
];
const FILE_TYPE_MATCH={
    '.doc':{type:'word',icon:'word',img:''},
    'application/msword':{type:'word',icon:'word',img:''},
    '.docx':{type:'word',icon:'word',img:''},
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':{type:'word',icon:'word',img:''},
    '.xls':{type:'excel',icon:'excel',img:''},
    'application/vnd.ms-excel':{type:'excel',icon:'excel',img:''},
    '.xlsx':{type:'excel',icon:'excel',img:''},
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':{type:'excel',icon:'excel',img:''},
    '.ppt':{type:'ppt',icon:'ppt',img:''},
    'application/vnd.ms-powerpoint':{type:'ppt',icon:'ppt',img:''},
    '.pptx':{type:'ppt',icon:'ppt',img:''},
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':{type:'ppt',icon:'ppt',img:''},
    '.pdf':{type:'pdf',icon:'pdf',img:''},
    'application/pdf':{type:'pdf',icon:'pdf',img:''},
    '.txt':{type:'txt',icon:'txt',img:''},
    'text/plain':{type:'txt',icon:'txt',img:''},
    '.csv':{type:'csv',icon:'csv',img:''},
    'text/csv':{type:'csv',icon:'csv',img:''},
    '.rtf':{type:'rtf',icon:'rtf',img:''},
    'text/rtf':{type:'rtf',icon:'rtf',img:''},
    '.srt':{type:'srt',icon:'srt',img:''},
    '.sdlxliff':{type:'sdlxliff',icon:'sdlxliff',img:''},
    '.sdlxlf':{type:'sdlxlf',icon:'sdlxliff',img:''},
    '.xliff':{type:'xliff',icon:'xliff',img:''},
    '.xlf':{type:'xlf',icon:'xliff',img:''},
    '.tmx':{type:'tmx',icon:'tmx',img:''},
    '.tbx':{type:'tbx',icon:'tbx',img:''},
    '.sdltb':{type:'sdltb',icon:'sdltb',img:''},
    '.sdltm':{type:'sdltm',icon:'sdltm',img:''},
    '.mp4':{type:'mp4',icon:'mp4',img:''},
    '.zip':{type:'zip',icon:'zip',img:''},
    '.rar':{type:'rar',icon:'zip',img:''},
    '.7z':{type:'7z',icon:'zip',img:''},
    '.jpg':{type:'jpg',icon:'picture',img:''},
    '.jpeg':{type:'jpeg',icon:'picture',img:''},
    '.png':{type:'png',icon:'picture',img:''},
    '.bmp':{type:'bmp',icon:'picture',img:''},
    '.psd':{type:'psd',icon:'picture',img:''},
    '.html':{type:'html',icon:'html',img:''},
    '.htm':{type:'htm',icon:'htm',img:''},
    '.dita':{type:'dita',icon:'dita',img:''},
    '.idml':{type:'idml',icon:'idml',img:''},
    '.ditamap':{type:'ditamap',icon:'ditamap',img:''},
    '.vtt':{type:'vtt',icon:'vtt',img:''},
};
const DOCUMENT_STATUS_DIST = {
    'queue':{key:'queue',name:'排队中',realName:'排队中'},
    'pre-parsing':{key:'pre-parsing',name:'解析中',realName:'文档预解析中'},
    'pre-parsed':{key:'pre-parsed',name:'解析中',realName:'预解析完成'},
    'parsing':{key:'parsing',name:'解析中',realName:'文档解析中'},
    'parsed':{key:'parsed',name:'解析中',realName:'解析完成'},
    'pre-translation':{key:'pre-translation',name:'预翻译中',realName:'预翻译中'},
    'pre-translated':{key:'pre-translated',name:'翻译完成',realName:'预翻译完成'},
    'converting':{key:'converting',name:'转换中',realName:'文档转换中'},
    'converted':{key:'converted',name:'转换完成',realName:'文档转换完成'},
    'convert-error':{key:'convert-error',name:'转换失败',realName:'文档转换失败'},
    'error':{key:'error',name:'处理失败'},
    'trans-error':{key:'trans-error',name:'翻译失败'},
    'parse-error':{key:'parse-error',name:'解析失败'},
    'limited': {key: 'limited', name:'流量不足'},
}
const LANGUAGE_DICT = {
    'zh':{key:'zh',name:'中文',shortName:'中'},
    'en':{key:'en',name:'英语',shortName:'英'},
    'ru':{key:'ru',name:'俄语',shortName:'俄'},
    'fr':{key:'fr',name:'法语',shortName:'法'},
    'de':{key:'de',name:'德语',shortName:'德'},
    'es':{key:'es',name:'西班牙语',shortName:'西'},
    'pt':{key:'pt',name:'葡萄牙语',shortName:'葡'},
    'ar':{key:'ar',name:'阿拉伯语',shortName:'阿'},
    'ja':{key:'ja',name:'日语',shortName:'日'},
    'ko':{key:'ko',name:'韩语',shortName:'韩'},
    'th':{key:'th',name:'泰语',shortName:'泰'},
    'vi':{key:'vi',name:'越南语',shortName:'越语'},
    'km':{key:'km',name:'高棉语',shortName:'高棉'},
    'ms':{key:'ms',name:'马来语',shortName:'马'},
    'id':{key:'id',name:'印尼语',shortName:'印尼'},
    'cs':{key:'cs',name:'捷克语',shortName:'捷'},
    'it':{key:'it',name:'意大利语',shortName:'意'},
    'nl':{key:'nl',name:'荷兰语',shortName:'荷'},
    'pt':{key:'pt',name:'葡萄牙语',shortName:'葡'},
    'bg':{key:'bg',name:'保加利亚语',shortName:'保加利亚'},
    'bs':{key:'bs',name:'波斯尼亚语',shortName:'波斯尼亚'},
    'el':{key:'el',name:'希腊语',shortName:'希腊'},
    'fa':{key:'fa',name:'波斯语',shortName:'波斯'},
    'hr':{key:'hr',name:'克罗地亚语',shortName:'克罗地亚'},
    'hu':{key:'hu',name:'匈牙利语',shortName:'匈牙利'},
    'he':{key:'he',name:'希伯来语',shortName:'希伯来'},
    'et':{key:'et',name:'爱沙尼亚语',shortName:'爱沙尼亚'},
    'sl':{key:'sl',name:'斯洛文尼亚语',shortName:'斯洛文尼亚'},
    'ur':{key:'ur',name:'乌尔都语',shortName:'乌尔都'},
    'tr':{key:'tr',name:'土耳其语',shortName:'土耳其'},
    'pl':{key:'pl',name:'波兰语',shortName:'波兰'},
    'ro':{key:'ro',name:'罗马尼亚语',shortName:'罗马尼亚'},
    'sk':{key:'sk',name:'斯洛伐克语',shortName:'斯洛伐克'},
    'uk':{key:'uk',name:'乌克兰语',shortName:'乌克兰'},
    'sq':{key:'sq',name:'阿尔巴尼亚语',shortName:'阿尔巴尼亚'},
    'mk':{key:'mk',name:'马其顿语',shortName:'马其顿'}, 
    'ug':{key:'ug',name:'维吾尔语',shortName:'维语'}, 
};
const RTL_LANGUAGE = ['ar', 'he', 'fa', 'ur', 'ug', ];
const DOMAIN_AND_ENGINE_LIST = [
    {
        key:'general',
        name:'通用领域',
        engines:[
            {key:'ali', name:'阿里', icon:'ali'},
            // {key:'zhongyi', name:'中译', icon:'zhongyi'},
            {key:'xunfei', name:'讯飞', icon:'xunfei'},
            {key:'weiruan', name:'微软', icon:'weiruan'},
            {key:'huoshan', name:'火山', icon:'huoshan'},
            {key:'baidu', name:'百度', icon:'baidu'},
            {key:'sougou', name:'搜狗', icon:'sougou'},
            {key:'youdao', name:'有道', icon:'youdao'},
            // {key:'jinshan', name:'金山', icon:'jinshan'},
            {key:'xiaoniu', name:'小牛', icon:'xiaoniu'},
            {key:'tengxun', name:'腾讯', icon:'tengxun'},
            {key:'aws', name:'亚马逊', icon:'aws'},
            {key:'modern-mt', name:'ModernMT', icon:'modern-mt'},
            {key:'ibm', name:'IBM', icon:'ibm'},
        ]
    },{
        key:'medicine',
        name:'生物医药',
        engines:[
            {key:'ali', name:'阿里', icon:'ali'},
            // {key:'jinshan', name:'金山', icon:'jinshan'},
            {key:'baidu', name:'百度', icon:'baidu'},
        ]
    },{
        key:'finance',
        name:'金融财经',
        engines:[
            // {key:'jinshan', name:'金山', icon:'jinshan'},
            {key:'baidu', name:'百度', icon:'baidu'},
        ]
    },{
        key:'novel',
        name:'网络文学',
        engines:[
            // {key:'jinshan', name:'金山', icon:'jinshan'},
            {key:'baidu', name:'百度', icon:'baidu'},
        ]
    }
];

const DOC_SPLIT_OPTS = [
    {label: 'SENTENCE', name: '按句拆分'}, {label: 'PARAGRAPH', name: '按段拆分'}
];

const DOC_SETTING_OPTS = [
    {
        type: 'word', 
        icon:'word', 
        name: i18n.t('config.DOC_SETTING_OPTS_1_name'), 
        indeterminate: true,
        checkAll: false,
        options: [
            {
                key:'translateWordHidden',
                label: i18n.t('config.DOC_SETTING_OPTS_1_options_1_label'),
                value: false
            },{
                key:'translateWordHeaderFooter',
                label: i18n.t('config.DOC_SETTING_OPTS_1_options_2_label'), 
                value: true,
            },{
                key: 'translateWordComment',
                label: i18n.t('config.DOC_SETTING_OPTS_1_options_3_label'),
                value: false,
            },{
                key: 'tabBreak',
                label: i18n.t('config.DOC_SETTING_OPTS_1_options_4_label'),
                value: false,
            },
        ]
    },{
        type: 'excel', 
        icon:'excel', 
        name: i18n.t('config.DOC_SETTING_OPTS_2_name'), 
        indeterminate: false,
        checkAll: false,
        options: [
            {
                key:'translateExcelHidden',
                label: i18n.t('config.DOC_SETTING_OPTS_2_options_1_label'),
                value: false
            },{
                key:'splitExcelByParagraph',
                label: i18n.t('config.DOC_SETTING_OPTS_2_options_2_label'),
                value: false
            }
        ]
    },{
        type: 'ppt', 
        icon:'ppt', 
        name: i18n.t('config.DOC_SETTING_OPTS_3_name'), 
        indeterminate: false,
        checkAll: false,
        options: [
            {
                key:'translatePptHidden',
                label: i18n.t('config.DOC_SETTING_OPTS_3_options_1_label'),
                value: false
            }
        ]
    },
];
const FILE_ANALYSIS_SETTING_OPTS = [
    {
        type: 'OUTER_REPEAT',
        name: i18n.t('config.FILE_ANALYSIS_SETTING_OPTS_1_name'),
        lock: false,
        calculate: true,
    },{
        type: 'INNER_REPEAT',
        name: i18n.t('config.FILE_ANALYSIS_SETTING_OPTS_2_name'),
        lock: false,
        calculate: true,
    },{
        type: 'UNTRANSLATED',
        name: i18n.t('config.FILE_ANALYSIS_SETTING_OPTS_3_name'),
        lock: true,
        calculate: false,
    },{
        type: 'MATCH_100',
        name: i18n.t('config.FILE_ANALYSIS_SETTING_OPTS_4_name'),
        lock: false,
        calculate: true,
    },{
        type: 'MATCH_101',
        name: i18n.t('config.FILE_ANALYSIS_SETTING_OPTS_5_name'),
        lock: false,
        calculate: true,
    },{
        type: 'MATCH_102',
        name: i18n.t('config.FILE_ANALYSIS_SETTING_OPTS_6_name'),
        lock: false,
        calculate: true,
    },
    
];

const TASK_IMPORT_ERROR_OPTS = {
    TASK_NOT_EXIST: i18n.t('config.TASK_IMPORT_ERROR_OPTS_TASK_NOT_EXIST'),
    TASK_STATUS_ABNORMAL: i18n.t('config.TASK_IMPORT_ERROR_OPTS_TASK_STATUS_ABNORMAL'),
    IMPORT_FILE_NON_EMPTY: i18n.t('config.TASK_IMPORT_ERROR_OPTS_IMPORT_FILE_NON_EMPTY'),
    FILE_TYPE_NOT_SUPPORTS: i18n.t('config.TASK_IMPORT_ERROR_OPTS_FILE_TYPE_NOT_SUPPORTS'),
    FILE_UPLOAD_FAILURE: i18n.t('config.TASK_IMPORT_ERROR_OPTS_FILE_UPLOAD_FAILURE'),
    FILE_DOES_NOT_EXIST: i18n.t('config.TASK_IMPORT_ERROR_OPTS_FILE_DOES_NOT_EXIST'),
    NO_AVAILABLE_DATA: i18n.t('config.TASK_IMPORT_ERROR_OPTS_NO_AVAILABLE_DATA'),
    PARSE_TERM_FILE_HAS_ERROR: i18n.t('config.TASK_IMPORT_ERROR_OPTS_PARSE_TERM_FILE_HAS_ERROR'),
    OPERATION_FAILURE: i18n.t('config.TASK_IMPORT_ERROR_OPTS_OPERATION_FAILURE'),
};

const PRIVACY_POLICY_CONTENT = `隐私政策`;
const SERVICE_TERMS_CONTENT = `服务条款`;

const ENGINE_ICON_LIST = ['custom-1', 'custom-2', 'custom-3', 'custom-4', 'custom-5', 'custom-6', 'custom-7', 'robot']

export default{
    MENU_DATA,
    FILE_ACCEPT_TYPE,
    FILE_TYPE_MATCH,
    LANGUAGE_DICT,
    RTL_LANGUAGE,
    DOMAIN_AND_ENGINE_LIST,
    DOCUMENT_STATUS_DIST,
    PRIVACY_POLICY_CONTENT,
    SERVICE_TERMS_CONTENT,
    DOC_SPLIT_OPTS,
    DOC_SETTING_OPTS,
    ENGINE_ICON_LIST,
    FILE_ANALYSIS_SETTING_OPTS,
    TASK_IMPORT_ERROR_OPTS,
}
